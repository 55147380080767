<template>
  <div class="box">
    <goodsList :coreList='coreList'></goodsList>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import goodsList from '@/components/goodsList.vue'
export default {
  name: 'coreJiShuGoods',
  data () {
    return {};
  },
  computed: {
    ...mapState('coreJiShu', ['coreList'])
  },
  components: {
    goodsList
  },

  created () { },

  mounted () { },

  methods: {

  }
}
</script>
<style lang='scss' scoped>
	.box {
		margin-top: -350px;
	}
</style>
